/* Contact.css */

.contact-nav-container {
    position: relative;
    z-index: 1; /* Ensure the header section is behind the contact form */
    overflow: hidden; /* Ensure the blur effect is contained within the container */
    -webkit-backdrop-filter: blur(20px); /* Blur effect for WebKit browsers */
    backdrop-filter: blur(20px); /* Blur effect for other browsers */
}

.contact-heading {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://vipratechsolutions.odoo.com/web/image/website.s_parallax_default_image");
    text-align: center;
    padding: 40px 0;
}

.contact-heading-text {
    font-size: 50px;
    padding: 20px;
    color: #ffffff;
    opacity: 100;
    font-weight: bold;
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
}

.contact-form-heading {
    padding-top: 20px;
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    font-size: 16px;
}

.contact-intro-text {
    font-size: 1.2rem;
    font-weight: 500;
}

@media (max-width: 991px) {
    .contact-form-company-mail {
        padding-top: 20px;
    }
}

.contact-form {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    margin-top: 20px;
    padding: 30px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-form .contact-form-row {
    margin-bottom: 20px;
}

.contact-form .form-label {
    font-weight: bold;
}

.contact-form .form-control {
    border-radius: 5px;
    padding: 10px;
}

.contact-form button {
    margin: 15px 0;
    width: auto;
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    background-color: #09090a;
    border-color: #0a0a0a;
    color: #ffffff;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.contact-form button:hover {
    background-color: #0056b3;
    border-color: #0056b3;
}

.alert {
    margin-bottom: 20px;
}

.close{
    padding-top: 1rem !important;
}

/* Responsive styles */
@media (max-width: 768px) {
    .contact-form {
        padding: 10px;
    }
}
