.hero-block {
    margin: 0 0 0;
}

/* .hero-block .carousel-item:before {
    content: '';
    background: rgba(200, 198, 198, 0.7);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
} */

.hero-block .carousel-item img {
    width: 100%;
}

.hero-block .carousel-caption {
    left: 25%;
    right: 25%;
    /* transform: translateY(-20%);
    -webkit-transform: translateY(-20%); */
    top: 10%;
}

.hero-block h1 {
    color: white;
    font-weight: 800;
    font-family: 'Gilroy, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif';
}

.hero-block h4 {
    margin: 0 0 30px;
    color: white;
    font-weight: 800;
    font-family: 'Gilroy, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif';

}

@media screen and (max-width: 768px) {
    .hero-block h4 {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .hero-block h1 {
        font-size: small;
    }
}