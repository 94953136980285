.services {
    padding: 7rem 0 6rem;
    width: 100%;
    background-color: #FAF8FF;
    font-family: 'Poppins', sans-serif;
}
.service {
    margin-left: 2rem;
    margin-right: 2rem;
    align-items: center;
}
/* .service-card {
    flex: 1;
    margin: 10px;
    padding: 20px;
    box-shadow: 0 0px 60px 0 rgb(0 0 0 / 2%);
    border-radius: 0.5rem;
    position: relative;
    background: linear-gradient(#5835f3, rgb(232, 188, 219));
    text-align: center;
    z-index: 1;
    height: 300px; 
} */

.service-card {
    flex: 1;
    margin: 10px;
    padding: 20px;
    text-align: center;
    background: linear-gradient(#9b7070, rgb(14, 13, 14)); 
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    min-width: 250px; /* Set a minimum width for the cards */
    display: flex; /* Use flexbox */
    flex-direction: column; /* Stack items vertically */
    justify-content: space-between; /* Space items evenly */
}

.service-text-container {
    height: 13rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media (max-width: 1200px) {
    .service-text-container {
        height: 20rem; /* Height for screens 1200px and below */
    }
}

@media (max-width: 992px) {
    .service-text-container {
        height: 18rem; /* Height for screens 992px and below */
    }
}

@media (max-width: 768px) {
    .service-text-container {
        height: 18rem; /* Height for screens 768px and below */
    }
}

@media (max-width: 576px) {
    .service-text-container {
        height: 12rem; /* Height for screens 576px and below */
    }
}

.service-text-container h4 {
    color: white;
}

.service-card #main-content {
    height: 80%;
}

.service-card #know-more {
    height: 20%;
}

/* .service-card button {
    top: 10px;
    background: #7355F7;
    padding: 12px 30px;
    display: inline-block;
    border-radius: 50px;
    font-size: 15px;
    font-weight: 800;
    line-height: 1.2;
    text-transform: uppercase;
    border: none;
    color: #fff;
    transition: 0.4s;
} */

.service-card h3 {
    color: white;
}

.service-card p {
    color: white;
}

.service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
}


.service-card:hover .serviceImg{
    height: 70px;
}
.service-card:hover .bookingBox {
    display: block;
    margin-bottom: -2rem;
    transition: 0.4s;
}
.service-card:hover .serviceName {
    padding: 0.2rem 0 0.2rem;
}
.service-card:hover .serviceDes {
    line-height: 1.5;
}
.service-card:hover::before {
    transform: scaleY(1);
}

.service-card::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: transform 300ms ease-out;
    transform: scaleY(0);
    border-radius: 0.5rem;
    background-color: #0a0a0a;
}

.serviceImg {
    height: 90px;
    background-color: rgb(213 196 255);
    padding: 0.2rem 0.5rem;
    border-radius: 0.5rem;
    transition: 0.4s;
}

.serviceDes {
    color: #666;
    font-weight: 500;
    line-height: 1.9;
    font-family: sans-serif;
    font-size: 1rem;
    margin-bottom: 0;
}
.serviceName {
    font-weight: 700;
    font-size: 1.4rem;
    padding: 1rem 0;
    margin-bottom: 0;
}
.bookingBox {
    display: none;
    transition: 2s;
}
.bookingBtn {
    border-radius: 1.8rem;
    font-weight: 500;
    font-size: 1rem;
    outline: none;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    background: #7355F7;
    margin-top: 0.3rem;
}

.servicePrice {
    color: #755BFF;
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 0;
}

.bookingBtn:hover {
    background: #4B24F5;
}