/* Reset default margin and padding */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Container styles */
.technical-expertise-container {
    margin: 0 auto;
    padding: 0 20px;
}

/* Section styles */
.section {
    padding: 50px 0;
}

/* Card styles */
.technical-card {
    flex: 1;
    margin: 10px;
    padding: 20px;
    text-align: center;
    background-color: #f8f9fa; 
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    min-width: 250px; /* Set a minimum width for the cards */
    display: flex; /* Use flexbox */
    flex-direction: column; /* Stack items vertically */
    justify-content: space-between; /* Space items evenly */
}

.technical-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
}

.icon-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.icon {
    width: 50px;
    height: 50px;
    color: #007bff;
    margin: 0 10px;
}

.text-container {
    /* height: 300px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

h4 {
    color: #000000; /* Black text color */
    font-size: 1.5rem;
    margin-bottom: 10px;
}

p {
    color: #000000; /* Black text color */
    font-size: 1rem;
    line-height: 1.5;
}

.knowMoreButton {
    background-color: #7355F7; /* Primary button color */
    color: #ffffff; /* White button text color */
    border: none;
    margin-bottom: 7px;
    border-radius: 5px;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.knowMoreButton:hover {
    background-color: linear-gradient(#5835f3, rgb(232, 188, 219)); /* Hover effect */
}