@import url(https://fonts.googleapis.com/css2?family=Poppins&family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.scrollBtn {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    cursor: pointer;
    font-weight: 400;
    color: #fff;
    z-index: 112;
    display: inline-block;
    height: 2.4rem;
    width: 2.4rem;
    background: #7154F3;
    border-radius: 50%;
    transition: 0.3s;
    border: none;
    box-shadow: 0 2px 15px rgb(88 69 206 / 70%);
}
.scrollBtn:hover {
    background-color: #4B24F5;
}
.footer {
    background: #1e1e1f;
    background-image: url(/static/media/footerbg.23fa6ea0.png);
    width: 100%;
    font-family: 'Poppins', sans-serif;
    margin: 0!important;
}
.footerBox .footerLogo {
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 0px 60px 0 rgb(0 0 0 / 20%);
    height: 70px;
    padding: 0.5rem;
}
.linkIcon {
    color: #141414;
    font-size: 2rem;
    cursor: pointer;
    background: white;
    padding: 5px;
    margin: 0 0.5rem;
    border-radius: 0.3rem;
}
.footerLink,
.fAboutUs {
    padding: 1.5rem 2rem;
}

.footerLink h5,
.fAboutUs h5 {
    color: rgba(240, 255, 255, 0.925);
    padding-top: 0.5rem;
    font-weight: 400;
    display: inline-block;
    position: relative;
}

.fAboutUs h2 {
    color: rgba(240, 255, 255, 0.925);
    padding-top: 0.5rem;
    font-weight: 900;
    display: inline-block;
    position: relative;
}

.footerLink h5 {
    padding-bottom: 0.5rem;
}
.footerLink h5::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 60px;
    border-radius: 70px;
    height: 4px;
    background: rgba(240, 255, 255, 0.925);;
}
.footerLink > .aboutUsDes {
    color: rgba(255, 255, 255, 0.6);
    font-weight: 400;
}
.footerLink li {
    list-style: none;
    color: rgba(255, 255, 255, 0.6);
    font-size: 1rem;
    transition: 0.4s;
    margin: 1.1rem 0;
    font-weight: 400;
}
.footerLink li:hover {
    margin-left: 0.7rem;
    color: #fff;
}

.footerLink a {
    color: rgba(255, 255, 255, 0.6);
    text-decoration: none;
}
.footerLink a:hover {
    text-decoration: none;
}
.footArrowIcon {
    font-size: 0.9rem;
}

.fAboutUs p {
    color: rgba(255, 255, 255, 0.699);
    font-weight: 400;
    margin: 1rem 0;

}
.fAboutUs ul {
    margin: 0;
    padding: 0;
}
.fAboutUs li {
    list-style: none;
    float: left;
}
.fAboutUs li a {
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    text-align: center;
    line-height: 2.5rem;
    background: #fff;
    margin-right: 5px;
    color:#0b0b0c;
    transition: 0.4s;
}
.fAboutUs li a:hover{
    background: #181818;
    color: #fff;
}

.copyRight {
    background-color: #151516;
    color: rgba(255, 255, 255, 0.692);
    font-weight: 400;
    font-size: 0.911rem;
    text-align: center;
    margin-bottom: 0;
    padding: 1rem 0;
}
.copyRight > .fHighlight {
    color: #fff;
    font-weight: 400;
    font-size: 1rem;
}


/* FooterInfo */

.footerInfo {
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    padding: 3rem 0 2rem;
}

.fContactInfo p {
    color: rgba(255, 255, 255, 0.6);
    font-size: 1rem;
    margin-bottom: 0.2rem;
    margin-left: 0.7rem;
}
.fContactIcon {
    font-size: 2.5rem;
    color: rgba(255, 255, 255, 0.822);
}

.fContactInfo1  p {
    color: #fff!important;
    font-weight: 500;
    font-size: 1.5rem!important;
}
.fContactInfo1 .fContactIcon {
    color: #fff;
    font-weight: 500;
    font-size: 3rem;
}


/* Animation border */

.animate-border {
    position: relative;
    display: block;
    width: 115px;
    height: 3px;
    background: #1b1b1b;
}
  
.animate-border:after {
    position: absolute;
    content: "";
    width: 35px;
    height: 3px;
    left: 0;
    bottom: 0;
    border-left: 20px solid #fff;
    animation: animborder 3s linear infinite;
  }
  
@keyframes animborder {
    0% {
        transform: translateX(0px);
    }
    100% {
        transform: translateX(81px);
    }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.contactUsBtnFooter {
  outline: none;
  border: none;
  border-radius: 2rem;
  font-weight: 950;
  font-size: 1.25rem;
  color: #131314;
  background: #f8f8f8;
  padding: 0.875rem 1.75rem;
  /* margin: 0.5rem 1rem; */
  transition: 0.4s;
}
/* Reset default margin and padding */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Container styles */
.technical-expertise-container {
    margin: 0 auto;
    padding: 0 20px;
}

/* Section styles */
.section {
    padding: 50px 0;
}

/* Card styles */
.technical-card {
    flex: 1 1;
    margin: 10px;
    padding: 20px;
    text-align: center;
    background-color: #f8f9fa; 
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    min-width: 250px; /* Set a minimum width for the cards */
    display: flex; /* Use flexbox */
    flex-direction: column; /* Stack items vertically */
    justify-content: space-between; /* Space items evenly */
}

.technical-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
}

.icon-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.icon {
    width: 50px;
    height: 50px;
    color: #007bff;
    margin: 0 10px;
}

.text-container {
    /* height: 300px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

h4 {
    color: #000000; /* Black text color */
    font-size: 1.5rem;
    margin-bottom: 10px;
}

p {
    color: #000000; /* Black text color */
    font-size: 1rem;
    line-height: 1.5;
}

.knowMoreButton {
    background-color: #7355F7; /* Primary button color */
    color: #ffffff; /* White button text color */
    border: none;
    margin-bottom: 7px;
    border-radius: 5px;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.knowMoreButton:hover {
    background-color: linear-gradient(#5835f3, rgb(232, 188, 219)); /* Hover effect */
}
.services {
    padding: 7rem 0 6rem;
    width: 100%;
    background-color: #FAF8FF;
    font-family: 'Poppins', sans-serif;
}
.service {
    margin-left: 2rem;
    margin-right: 2rem;
    align-items: center;
}
/* .service-card {
    flex: 1;
    margin: 10px;
    padding: 20px;
    box-shadow: 0 0px 60px 0 rgb(0 0 0 / 2%);
    border-radius: 0.5rem;
    position: relative;
    background: linear-gradient(#5835f3, rgb(232, 188, 219));
    text-align: center;
    z-index: 1;
    height: 300px; 
} */

.service-card {
    flex: 1 1;
    margin: 10px;
    padding: 20px;
    text-align: center;
    background: linear-gradient(#9b7070, rgb(14, 13, 14)); 
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    min-width: 250px; /* Set a minimum width for the cards */
    display: flex; /* Use flexbox */
    flex-direction: column; /* Stack items vertically */
    justify-content: space-between; /* Space items evenly */
}

.service-text-container {
    height: 13rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media (max-width: 1200px) {
    .service-text-container {
        height: 20rem; /* Height for screens 1200px and below */
    }
}

@media (max-width: 992px) {
    .service-text-container {
        height: 18rem; /* Height for screens 992px and below */
    }
}

@media (max-width: 768px) {
    .service-text-container {
        height: 18rem; /* Height for screens 768px and below */
    }
}

@media (max-width: 576px) {
    .service-text-container {
        height: 12rem; /* Height for screens 576px and below */
    }
}

.service-text-container h4 {
    color: white;
}

.service-card #main-content {
    height: 80%;
}

.service-card #know-more {
    height: 20%;
}

/* .service-card button {
    top: 10px;
    background: #7355F7;
    padding: 12px 30px;
    display: inline-block;
    border-radius: 50px;
    font-size: 15px;
    font-weight: 800;
    line-height: 1.2;
    text-transform: uppercase;
    border: none;
    color: #fff;
    transition: 0.4s;
} */

.service-card h3 {
    color: white;
}

.service-card p {
    color: white;
}

.service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
}


.service-card:hover .serviceImg{
    height: 70px;
}
.service-card:hover .bookingBox {
    display: block;
    margin-bottom: -2rem;
    transition: 0.4s;
}
.service-card:hover .serviceName {
    padding: 0.2rem 0 0.2rem;
}
.service-card:hover .serviceDes {
    line-height: 1.5;
}
.service-card:hover::before {
    transform: scaleY(1);
}

.service-card::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: transform 300ms ease-out;
    transform: scaleY(0);
    border-radius: 0.5rem;
    background-color: #0a0a0a;
}

.serviceImg {
    height: 90px;
    background-color: rgb(213 196 255);
    padding: 0.2rem 0.5rem;
    border-radius: 0.5rem;
    transition: 0.4s;
}

.serviceDes {
    color: #666;
    font-weight: 500;
    line-height: 1.9;
    font-family: sans-serif;
    font-size: 1rem;
    margin-bottom: 0;
}
.serviceName {
    font-weight: 700;
    font-size: 1.4rem;
    padding: 1rem 0;
    margin-bottom: 0;
}
.bookingBox {
    display: none;
    transition: 2s;
}
.bookingBtn {
    border-radius: 1.8rem;
    font-weight: 500;
    font-size: 1rem;
    outline: none;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    background: #7355F7;
    margin-top: 0.3rem;
}

.servicePrice {
    color: #755BFF;
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 0;
}

.bookingBtn:hover {
    background: #4B24F5;
}
.hero-block {
    margin: 0 0 0;
}

/* .hero-block .carousel-item:before {
    content: '';
    background: rgba(200, 198, 198, 0.7);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
} */

.hero-block .carousel-item img {
    width: 100%;
}

.hero-block .carousel-caption {
    left: 25%;
    right: 25%;
    /* transform: translateY(-20%);
    -webkit-transform: translateY(-20%); */
    top: 10%;
}

.hero-block h1 {
    color: white;
    font-weight: 800;
    font-family: 'Gilroy, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif';
}

.hero-block h4 {
    margin: 0 0 30px;
    color: white;
    font-weight: 800;
    font-family: 'Gilroy, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif';

}

@media screen and (max-width: 768px) {
    .hero-block h4 {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .hero-block h1 {
        font-size: small;
    }
}

.blog-detail {
  padding: 20px;
}

.blog-cover-image {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

.blog-detail h1 {
  margin-bottom: 20px;
  font-weight: bold;
}

.blog-detail div {
  word-wrap: break-word;
}

.blog-detail img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  border-radius: 8px;
}

.blog-list {
    padding: 20px;
  }
.popImg {
    height: 45px;
    border-radius: 50%;
    cursor: pointer!important;
    margin: 0.2rem 0 0 0.7rem;
}
.popUserImg {
    height: 60px;
    border-radius: 50%;
    margin-bottom: 8px;
}
.userName, 
.userEmail{
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 700;
}
.userEmail {
    margin: 0.1rem 0 0.5rem;
    font-weight: 600;
    font-size: 0.9rem;
    color: rgba(0,0,0,.7);
}
.profile {
    box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
    background: #fff;
    text-align: center;
    margin: 2.5rem auto;
    border-radius: 0.3rem;
    max-width: 500px;
}

.profile h2 {
    font-size: 35px;
    font-weight: 500;
    padding: 0.5rem 0;
    margin-bottom: 0;
    background-color: #EFF2F7;
}

.profile img {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    margin: 1rem 0;
}

.profile h3 {
    font-size: 1.5rem;
    margin: 0.6rem 0;
}

.profile h5 {
    font-size: 1.1rem;
    color: rgba(0,0,0,.7);
}

.profileInfo {
    padding: 1.3rem;
}

.mainBtn {
    background: #7355F7;
    padding: 13px 36px;
    display: inline-block;
    border-radius: 50px;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.2;
    text-transform: uppercase;
    border: none;
    color: #fff;
    transition: 0.4s;
    cursor: pointer;
}
.mainBtn:hover {
    background-color: #4C25F5;
}
#statusBtn {
    border: none;
    outline: none;
}
#pending {
    color: white;
    background: rgb(255 78 96);
}
#pending:hover{
    background: rgb(228 72 88);
}
#ongoing {
    color: white;
    background: rgb(73 146 255);
}
#ongoing:hover{
    background: #4185e2;
}
#done {
    color: white;
    background: rgb(31 204 123);
}
#done:hover{
    background: rgb(31 177 109);
}
.dropdown-menu{
    padding: 1px!important;
    border: 1px solid black!important;
}

#dropdown-basic-button .btn {
    border: none;
    box-shadow: none;
}
.orderList{
    background: white;
    box-shadow: 0 2px 5px 1px rgb(64 60 67 / 4%);
    padding: 2rem;
    border-radius: 1rem;
    width: 100%;
    margin-top: 2.5rem;
}
.tableTitle {
    background: #efefef;
    border-radius: 0.4rem;
    padding: 0.2rem 1rem;
}
.tableTitle p {
    margin-bottom: 0;
    font-weight: 500;
}

.table-hover tbody tr:hover {
    color: #212529;
    background-color: #fcfcfc!important;
}

.table td, .table th {
    border-top: 0!important;
}



.addServiceForm{
    background: white;
    box-shadow: 0 2px 5px 1px rgb(64 60 67 / 4%);
    padding: 2rem;
    border-radius: 1rem;
    margin: 2.5rem 0;
}
  
.uploadBtn {
    color: #6544f8;
    border: 1px solid #5c38ff;
    background-color: rgba(147, 123, 255, 0.227);
    padding: 8px 20px;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    outline: 0;
    width: 250px;
    transition: 0.4s;
}

.uploadBtn:hover {
    color: #6544f8;
    border: 1px solid #5c38ff;
    background-color: rgba(147, 123, 255, 0.384);
}

.form-label {
    margin-bottom: .6rem!important;
}

.sideBox{
    height: 100%;
}


#sideNavbar {
    font-family: 'Poppins', sans-serif;
    transition: 0.4s;
}
#sideNavbar ul {
   margin: 0;
   padding: 0.5rem 0 0.5rem 2rem;
   list-style: none;
}
#sideNavbar ul li a {
    padding: 0.6rem 0.8rem;
    display: block;
    font-size: 1.1rem;
    color: #878787;
    border-left: 3px solid transparent;
}
#sideNavbar ul li a:hover {
    text-decoration: none;
    color: #000;
    background-color: #EFF2F7;
}

.activePage{
    color: #000!important;
    background-color: #f1eeff!important;
    border-left: 3px solid #7255F6!important;
}
.activePage:hover {
    color: #000!important;
    background-color: #f1eeff!important;
    border-left: 3px solid #7255F6!important;
}
.iconC {
    margin-right: 5px;
}

.sideBrand {
    color: #000;
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sideBrand h2 {
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 0;
}
.sideBrnIcon {
    font-size: 2.5rem;
    font-weight: 700;
    color: #7255F6;
    margin-right: 5px;
}
.bookingList {
    background: white;
    box-shadow: 0 2px 5px 1px rgb(64 60 67 / 4%);
    padding: 1.5rem;
    margin: 0.9rem 0;
    border-radius: 0.5rem;
}
.bookingList img{
    height: 70px;
    border-radius: 0.3rem;
    padding: 0.2rem 0.5rem;
    background: #D5C4FF;
    margin-bottom: 0.5rem;
}
.bookingList h6{
    margin: 0.5rem 0;
    color: #263179;
    font-size: 1.2rem;
    font-weight: 600;
}
.serviceState {
    padding: 0.5rem 1rem;
    border-radius: 0.3rem;
}

.bookForm {
    background: #fff;
    padding: 2rem 3rem;
    margin: 2.5rem 1rem;
    border-radius: 1rem;
    position: relative;
}

.form-select:focus,
.form-control:focus  {
    border-color: #8468ff!important;
    box-shadow: 0 0 0 0.2rem rgb(4 0 255 / 26%)!important;
}

.priceInput {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

.activeService {
    background: #66b0ffbe;
    color: #fff;
}

.toastIcon {
    height: 1.2rem;
}

.bookToast {
    position: absolute;
    right: 0;
    top: 1.5rem;
    z-index: 111;
    background: #fff;
}
.userReviewBox {
    background: #f1ecff;
    padding: 2.5rem 5rem;
    border-radius: 0.6rem;
    text-align: center;
    min-height: 100vh;
}
.userReviewBox button {
    margin: 0 0.5rem;
}
.reviewForm {
    background: white;
    box-shadow: 0 2px 5px 1px rgb(64 60 67 / 4%);
    padding: 2rem;
    border-radius: 1rem;
    width: 100%;
    margin: 2.5rem 0;
}
#dashboard {
    display: flex;
    width: 100%;
    min-height: 100vh;
    align-items: stretch;
    perspective: 1500px;
    background: #f4f7fc;
    font-family: 'Poppins', sans-serif;
  }
  
  #sidebar {
    min-width: 300px;
    max-width: 300px;
    height: 100vh;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: white;
    color: #878787;
    transition: all 0.6s cubic-bezier( 0.55, 0.055, 0.675, 0.19 ) ;
    padding-bottom: 0.5rem;
  }
  #sidebar.active {
    margin-left: -300px;
    transform: rotateY(100deg);
  }
  
  .sidebarContent {
    position: relative;
    height: 100%;
    width: 100%;
  }
  
  .backBtnBox {
    position: absolute;
    bottom: 0;
    padding: 0.2rem;
    width: 100%;
  }
  
  .backBtnBox .backBtn {
    display: block;
    width: 100%;
    text-transform: uppercase;
    background: #7254F6;
    border: none;
    font-size: 1rem;
    transition: 0.3s;
    padding: 0.4rem;
    font-weight: 500;
    color: #fff;
    border-radius: 0.3rem;
  }
  
  .backBtnBox .backBtn:hover{
    background: #4C25F5;
  }
  
  .backBtnBox a:hover {
    text-decoration: none;
  }
  
  #pageContent {
    width: 100%;
    min-height: 100vh;
    transition: all 0.3s;
    padding: 1rem;
  }
  
  .dashBoardHeader {
    display: flex;
    justify-content: space-between;
    padding: 0.6rem 2rem 0.6rem 1.3rem;
    margin-bottom: 1rem;
    background: #FFFFFF;
    align-items: center;
    border-radius: 0.2rem;
    box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
    transition: 0.4s;
    transform: button;
  }
  
  .dashBoardHeader h3 {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
    text-transform: uppercase;
  }
  
  .sideToggleBtn {
    width: 40px;
    height: 40px;
    background: #f5f5f5;
    cursor: pointer;
    outline: none;
    border: none;
  }
  
  /* Menu Hamburger Icon Animation */
  
  #nav-icon {
    width: 40px;
    height: 34px;
    position: relative;
    transform: rotate(0deg);
    transition: .7s ease-in-out;
    cursor: pointer;
    border-radius: 0.133rem;
    margin-right: 0.5rem;
    background: #eff2f7;
  }
  
  #nav-icon span {
    display: block;
    position: absolute;
    height: 3px;
    width: 50%;
    background: #696969;
    opacity: 1;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }
  
  #nav-icon span:nth-child(even) {
    left: 35%;
    border-radius: 0 5px 5px 0;
  }
  
  #nav-icon span:nth-child(odd) {
    left: 0.4rem;
    border-radius: 5px 0 0 5px;
  }
  
  #nav-icon span:nth-child(1), #nav-icon span:nth-child(2) {
    top: 7px;
  }
  
  #nav-icon span:nth-child(3), #nav-icon span:nth-child(4) {
    top: 15px;
  }
  
  #nav-icon span:nth-child(5), #nav-icon span:nth-child(6) {
    top: 23px;
  }
  
  #nav-icon.open span:nth-child(1),#nav-icon.open span:nth-child(6) {
    transform: rotate(45deg);
  }
  
  #nav-icon.open span:nth-child(2),#nav-icon.open span:nth-child(5) {
    transform: rotate(-45deg);
  }
  
  #nav-icon.open span:nth-child(1) {
    left: 10px;
    top: 14px;
  }
  
  #nav-icon.open span:nth-child(2) {
    left: calc(50% - 10px);
    top: 14px;
  }
  
  #nav-icon.open span:nth-child(3) {
    left: -50%;
    opacity: 0;
  }
  
  #nav-icon.open span:nth-child(4) {
    left: 100%;
    opacity: 0;
  }
  
  #nav-icon.open span:nth-child(5) {
    left: 10px;
    top: 15px;
  }
  
  #nav-icon.open span:nth-child(6) {
    left: calc(50% - 10px);
    top: 15px;
  }
  
  .adminBtn {
    margin-top: 1.8rem;
    margin-left: -1rem;
  }
  
  .makeAdmin {
      background: #FFFFFF;
      border-radius: 1rem;
      padding: 3rem 2rem 8rem;
      margin-top: 2rem;
      box-shadow: 0 2px 5px 1px rgb(64 60 67 / 4%);
  }
  
/* Contact.css */

.contact-nav-container {
    position: relative;
    z-index: 1; /* Ensure the header section is behind the contact form */
    overflow: hidden; /* Ensure the blur effect is contained within the container */
    -webkit-backdrop-filter: blur(20px); /* Blur effect for WebKit browsers */
    backdrop-filter: blur(20px); /* Blur effect for other browsers */
}

.contact-heading {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://vipratechsolutions.odoo.com/web/image/website.s_parallax_default_image");
    text-align: center;
    padding: 40px 0;
}

.contact-heading-text {
    font-size: 50px;
    padding: 20px;
    color: #ffffff;
    opacity: 100;
    font-weight: bold;
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
}

.contact-form-heading {
    padding-top: 20px;
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    font-size: 16px;
}

.contact-intro-text {
    font-size: 1.2rem;
    font-weight: 500;
}

@media (max-width: 991px) {
    .contact-form-company-mail {
        padding-top: 20px;
    }
}

.contact-form {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    margin-top: 20px;
    padding: 30px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-form .contact-form-row {
    margin-bottom: 20px;
}

.contact-form .form-label {
    font-weight: bold;
}

.contact-form .form-control {
    border-radius: 5px;
    padding: 10px;
}

.contact-form button {
    margin: 15px 0;
    width: auto;
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    background-color: #09090a;
    border-color: #0a0a0a;
    color: #ffffff;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.contact-form button:hover {
    background-color: #0056b3;
    border-color: #0056b3;
}

.alert {
    margin-bottom: 20px;
}

.close{
    padding-top: 1rem !important;
}

/* Responsive styles */
@media (max-width: 768px) {
    .contact-form {
        padding: 10px;
    }
}

.navBrn {
    display: flex;
    align-items: center;
}

.navIcon {
    height: 3.0em;
    margin-right: 1.2em;
}

.navStyle {
    box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
    background: white;
    position: fixed!important;
    width: 100%;
    z-index: 99!important;
    transition: 0.5s!important;
    font-family: 'Poppins', sans-serif;
}
.navDefault {
    transition: 0.5s!important;
    padding: 25px 0 40px 0!important;
    margin-bottom: 0rem;
}

.navText {
    font-size: 2rem;
    font-family: 'Poppins', sans-serif;
}

@media screen and (max-width: 1199px) {
    .navText {
        font-size: 1.2rem;
    }
    .navIcon {
        height: 2.5em;
        margin-right: 1em;
    }
}

.mainNav .nav-link {
    color: #ffffff !important;
    margin: 0.5rem 1.2rem!important;
    font-weight: 500!important;
    transition: 0.3s;
    font-size: 1.1rem;
    font-family: 'Poppins', sans-serif;
}
.mainNav .nav-link:after {
    content: '';
    display: block;
    margin: auto;
    height: 3px;
    width: 0px;
    background: transparent;
    transition: width .5s ease, background-color .5s ease;
}
.mainNav .nav-link:hover:after {
    width: 100%;
    background: #f6f6f7;
}

.navBrn {
    font-weight: 700;
    font-size: 1.5rem;
}
.brnIcon,
.navHighlight {
    color: #af6565;;
}
.brnIcon {
    font-weight: 800;
    font-size: 1.6rem
}

.loginBtn {
    outline: none;
    border: none;
    border-radius: 20px;
    font-weight: 800;
    color: #000000;
    background: #ffffff;
    padding: 0.5rem 1.5rem;
    margin: 0.5rem 1rem;
    transition: 0.4s;
}
.loginBtn:hover { 
    background : #ffffff
}
.navActiveClass{
    color:"red";
    font-weight:900;
}

@media (max-width: 981px) {
    .navDefault {
        background: white;
        padding: 0.8rem 0.5rem!important;
        width: 100%;
        z-index: 99!important;
        transition: 0.5s!important;
        text-align: center;
    }
    .navStyle{
        text-align: center;
    }
}
