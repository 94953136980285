
.blog-detail {
  padding: 20px;
}

.blog-cover-image {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

.blog-detail h1 {
  margin-bottom: 20px;
  font-weight: bold;
}

.blog-detail div {
  word-wrap: break-word;
}

.blog-detail img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  border-radius: 8px;
}
